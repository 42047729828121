<template>
  <div class="global-header">
    <div class="w-full sticky bg-white top-0 hr-16 z-50">
      <div class="container flex h-full items-center justify-between">
        <!--总体分为三个部分-->

        <!--左边部分 logo和标题  小于744px时隐藏，标题收集图标出现-->
        <div class="left-header flex h-full">
          <!--标题收缩部分-->
          <div class="flex h-full lg:hidden">
            <div class="flex h-full items-center px-1" @click="changeLineStatus">
              <div class="wr-6 hr-6" ref="showLine">
                <svg t="1704202999816" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8656" width="24" height="24">
                  <path
                    d="M133.310936 296.552327l757.206115 0c19.781623 0 35.950949-16.169326 35.950949-35.950949 0-19.781623-15.997312-35.950949-35.950949-35.950949L133.310936 224.650428c-19.781623 0-35.950949 16.169326-35.950949 35.950949C97.359987 280.383 113.529313 296.552327 133.310936 296.552327z"
                    fill="#575B66"
                    p-id="8657"
                  ></path>
                  <path
                    d="M890.51705 476.135058 133.310936 476.135058c-19.781623 0-35.950949 16.169326-35.950949 35.950949 0 19.781623 16.169326 35.950949 35.950949 35.950949l757.206115 0c19.781623 0 35.950949-16.169326 35.950949-35.950949C926.467999 492.304384 910.298673 476.135058 890.51705 476.135058z"
                    fill="#575B66"
                    p-id="8658"
                  ></path>
                  <path
                    d="M890.51705 727.447673 133.310936 727.447673c-19.781623 0-35.950949 15.997312-35.950949 35.950949s16.169326 35.950949 35.950949 35.950949l757.206115 0c19.781623 0 35.950949-15.997312 35.950949-35.950949S910.298673 727.447673 890.51705 727.447673z"
                    fill="#575B66"
                    p-id="8659"
                  ></path>
                </svg>
              </div>
              <div class="wr-6 hr-6 hidden" ref="closeLine">
                <svg t="1704196997584" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6532" width="24" height="24">
                  <path
                    d="M558.096874 509.244236l278.304494-276.863678c13.159718-13.094226 13.226233-34.31248 0.132006-47.472198-13.123902-13.189394-34.305317-13.226233-47.465035-0.132006L510.692215 461.669708 234.740302 184.877661c-13.062504-13.093203-34.310434-13.159718-47.470151-0.066515-13.129019 13.093203-13.160741 34.342156-0.066515 47.501874l275.885398 276.725532L184.918593 785.733385c-13.160741 13.094226-13.226233 34.310434-0.134053 47.472198 6.54609 6.581905 15.172561 9.900487 23.799032 9.900487 8.56098 0 17.125029-3.252067 23.672142-9.768481l278.236955-276.761348 278.473339 279.31245c6.54609 6.582929 15.142885 9.871835 23.768333 9.871835 8.594749 0 17.155728-3.288906 23.701818-9.80532 13.123902-13.093203 13.159718-34.305317 0.066515-47.465035L558.096874 509.244236 558.096874 509.244236zM558.096874 509.244236"
                    fill="#272636"
                    p-id="6533"
                  ></path>
                </svg>
              </div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
          <!--logo和标题部分-->
          <div class="flex flex-row h-full items-center gap-3 sm:hidden">
            <!--logo部分-->
            <router-link to="/" class="h-full wr-6 flex items-center">
              <div class="hr-6 wr-6">
                <img src="@/assets/images/logo.svg" alt="" width="24" height="24" />
              </div>
            </router-link>
            <!--标题部分-->
            <div class="title-bar flex flex-row h-full">
              <!--竖线部分-->
              <div class="vline h-full wr-4 flex items-center">
                <div class="vertical-line"></div>
              </div>
              <!--标题部分-->
              <div class="h-full flex flex-row">
                <div class="flex h-full items-center">
                  <a href="https://e.gitee.com/ETAS/doc/share/46c59b2eb901c223/?sub_id=9334019" target="_blank" rel="noopener" class="h-full flex items-center px-3">
                    <div class="flex items-center">
                      <span class="text-black text-tiny">{{ $t("nav.doc") }}</span>
                    </div>
                  </a>
                </div>
                <div class="flex h-full items-center">
                  <a href="https://e.gitee.com/ETAS/doc/share/d10989aabc81bb8f/?sub_id=9582132" target="_blank" rel="noopener" class="h-full flex items-center px-3">
                    <div class="flex items-center">
                      <span class="text-black text-tiny">{{ $t("nav.tutorial") }}</span>
                    </div>
                  </a>
                </div>
                <div class="flex h-full items-center">
                  <router-link tag="a" to="/faq" class="h-full flex items-center px-3">
                    <div class="flex items-center">
                      <span class="text-black text-tiny">{{ $t("nav.faq") }}</span>
                    </div>
                  </router-link>
                </div>
                <div class="flex h-full items-center">
                  <router-link tag="a" target="_blank" to="/" class="h-full flex items-center px-3">
                    <div class="flex items-center">
                      <!-- <span class="text-black text-tiny">{{ $t("nav.support") }}</span> -->
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--中间logo 部分，小于744px时出现-->
        <div class="flex h-full items-center lg:hidden">
          <div class="wr-6 hr-6">
            <img src="@/assets/images/logo.svg" alt="logo" width="24" height="24" />
          </div>
        </div>
        <!--右边部分 console和中英文切换-->
        <div class="flex flex-row h-full items-center gap-4">
          <!--控制台-->
          <div class="flex sml:hidden">
            <router-link tag="a" target="_blank" to="/" class="h-full flex items-center px-3">
              <div class="flex items-center">
                <!-- <span class="text-black text-tiny">Console</span> -->
              </div>
            </router-link>
          </div>
          <div class="flex h-full items-center">
            <div class="flex flex-col justify-center relative sml:hidden" ref="lgl" @click="changLang">
              <div class="flex px-1p py-1p" ref="enLargeIcon">
                <svg t="1705979092801" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6343" width="24" height="24">
                  <path
                    d="M864 64a96 96 0 0 1 96 96v704a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h704z m0 64H160a32 32 0 0 0-32 32v704a32 32 0 0 0 32 32h704a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z m-322.4 256c0-31.456 40.64-44.032 58.4-18.08l133.6 195.168V384a32 32 0 0 1 64 0v280.48c0 31.456-40.64 44.032-58.4 18.08l-133.6-195.168v177.088a32 32 0 1 1-64 0z"
                    fill="#515151"
                    p-id="6344"
                  ></path>
                  <path
                    d="M448 352a32 32 0 0 1 0 64H288v80h160a32 32 0 0 1 31.776 28.256L480 528a32 32 0 0 1-32 32H288v72.48h160a32 32 0 1 1 0 64H256a32 32 0 0 1-32-32V384a32 32 0 0 1 32-32z"
                    fill="#515151"
                    p-id="6345"
                  ></path>
                </svg>
              </div>
              <div class="flex px-1p py-1p hidden" ref="zhLargeIcon">
                <svg t="1706511156312" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12175" width="24" height="24">
                  <path
                    d="M903.616 0C969.984 0 1024 54.016 1024 120.384V903.68A120.576 120.576 0 0 1 903.552 1024H120.384A120.512 120.512 0 0 1 0 903.616V120.32C0 54.016 54.016 0 120.384 0H903.68z m0 69.12H120.32a51.328 51.328 0 0 0-51.264 51.264V903.68c0 28.288 23.04 51.264 51.264 51.264H903.68c28.288 0 51.264-23.04 51.264-51.264V120.32a51.392 51.392 0 0 0-51.264-51.264zM542.528 217.6v109.44H768v305.6h-59.904v-35.968H542.528v211.904h-62.08v-211.84h-164.48v35.904H256V327.104h224.96V217.6h61.568z m-62.08 168.32h-164.48v151.936h164.48V385.92z m227.648 0H543.04v151.936h165.056V385.92z"
                    fill="#515151"
                    p-id="12176"
                  ></path>
                </svg>
              </div>
              <div class="absolute flex top-150 left-n15 bd-1p br-2 hidden bg-white" ref="largeLangList">
                <div class="flex flex-col flex-1 pointer">
                  <ul class="lang-ui">
                    <li class="lang-li px-1" @click="changLanguage('zh')">
                      <div class="flex flex-row">
                        <div class="flex lang-svg px-3p py-3p">
                          <svg t="1706496592275" class="icon" viewBox="0 0 1664 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4257" width="24" height="24">
                            <path d="M0 0h1664v1024H0z" fill="#D71618" p-id="4258"></path>
                            <path
                              d="M268.8 411.52l102.4 74.24-39.04-120.32 102.4-74.88H307.84L268.8 169.6l-39.68 120.96H102.4l102.4 74.88-39.04 120.32 103.04-74.24zM569.6 188.8l47.36-11.52-44.8-18.56 3.2-48-30.72 37.12-45.44-18.56 25.6 40.96-30.72 37.12 46.72-11.52 25.6 40.96 3.2-48z m0 402.56l47.36-11.52-44.8-17.92 3.2-48.64-30.72 37.12-45.44-18.56 25.6 41.6-30.72 37.12 46.72-11.52L566.4 640l3.2-48.64z m112.64-277.12l48 7.68-33.92-34.56 22.4-42.88-43.52 21.76-33.28-35.2 6.4 48-42.88 21.76 47.36 8.32 7.04 48 22.4-42.88z m-14.72 173.44l39.68 26.88-16-45.44 37.76-30.08-48 1.92-16.64-46.08-13.44 46.72-48.64 1.92 40.32 26.88-13.44 46.72 38.4-29.44z"
                              fill="#FFF100"
                              p-id="4259"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex lang-space lh-30">
                          <span class="fs:lang">简体中文</span>
                        </div>
                      </div>
                    </li>
                    <li class="lang-li px-1" @click="changLanguage('en')">
                      <div class="flex flex-row">
                        <div class="flex lang-svg px-3p py-3p">
                          <svg t="1706496627537" class="icon" viewBox="0 0 1536 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5661" width="24" height="24">
                            <path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="5662"></path>
                            <path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="5663"></path>
                            <path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="5664"></path>
                            <path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="5665"></path>
                            <path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="5666"></path>
                            <path
                              d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z"
                              fill="#CC3440"
                              p-id="5667"
                            ></path>
                          </svg>
                        </div>
                        <div class="flex lang-space lh-30">
                          <span class="fs:lang"> English </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-center relative lgl:hidden" ref="sml" @click="changSmLang">
              <div class="flex px-1p" ref="enSmallIcon">
                <svg t="1705979092801" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6343" width="24" height="24">
                  <path
                    d="M864 64a96 96 0 0 1 96 96v704a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h704z m0 64H160a32 32 0 0 0-32 32v704a32 32 0 0 0 32 32h704a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z m-322.4 256c0-31.456 40.64-44.032 58.4-18.08l133.6 195.168V384a32 32 0 0 1 64 0v280.48c0 31.456-40.64 44.032-58.4 18.08l-133.6-195.168v177.088a32 32 0 1 1-64 0z"
                    fill="#515151"
                    p-id="6344"
                  ></path>
                  <path
                    d="M448 352a32 32 0 0 1 0 64H288v80h160a32 32 0 0 1 31.776 28.256L480 528a32 32 0 0 1-32 32H288v72.48h160a32 32 0 1 1 0 64H256a32 32 0 0 1-32-32V384a32 32 0 0 1 32-32z"
                    fill="#515151"
                    p-id="6345"
                  ></path>
                </svg>
              </div>
              <div class="flex px-1p hidden" ref="zhSmallIcon">
                <svg t="1706511156312" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12175" width="24" height="24">
                  <path
                    d="M903.616 0C969.984 0 1024 54.016 1024 120.384V903.68A120.576 120.576 0 0 1 903.552 1024H120.384A120.512 120.512 0 0 1 0 903.616V120.32C0 54.016 54.016 0 120.384 0H903.68z m0 69.12H120.32a51.328 51.328 0 0 0-51.264 51.264V903.68c0 28.288 23.04 51.264 51.264 51.264H903.68c28.288 0 51.264-23.04 51.264-51.264V120.32a51.392 51.392 0 0 0-51.264-51.264zM542.528 217.6v109.44H768v305.6h-59.904v-35.968H542.528v211.904h-62.08v-211.84h-164.48v35.904H256V327.104h224.96V217.6h61.568z m-62.08 168.32h-164.48v151.936h164.48V385.92z m227.648 0H543.04v151.936h165.056V385.92z"
                    fill="#515151"
                    p-id="12176"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute w-full bg-white hidden" ref="hiddenLine">
        <div class="w-full">
          <ul class="bg-black w-full py-3">
            <li class="w-full">
              <a href="https://e.gitee.com/ETAS/doc/share/46c59b2eb901c223/?sub_id=9334019" target="_blank" rel="noopener" class="h-full flex items-center px-3">
              <div class="w-90 bd-1 py-2 margin-center text-left">
                <span class="text-white fs:line">{{ $t("nav.doc") }}</span>
              </div>
            </a>
            </li>
            <li class="w-full">
              <a href="https://e.gitee.com/ETAS/doc/share/d10989aabc81bb8f/?sub_id=9582132" target="_blank" rel="noopener" class="h-full flex items-center px-3">
              <div class="w-90 bd-1 py-2 margin-center text-left">
                <span class="text-white"> {{ $t("nav.tutorial") }} </span>
              </div>
              </a>
            </li>
            <li class="w-full">
              <router-link tag="a" to="/faq" class="h-full flex items-center px-3">
              <div class="w-90 bd-1 py-2 margin-center text-left">
                <span class="text-white"> {{ $t("nav.faq") }} </span>
              </div>
            </router-link>
            </li>
            <!-- <li class="w-full">
              <div class="w-90 py-2 margin-center text-left">
                <span class="text-white"> {{ $t("nav.support") }} </span>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="absolute w-full bg-white hidden" ref="smLangSpace">
        <div class="w-full bg-black py-3">
          <ul class="w-full">
            <li class="w-full" @click="changSmLanguage('zh')">
              <div class="w-90 bd-1 py-2 margin-center">
                <div class="flex flex-row justify-end">
                  <div class="flex lang-svg px-3p py-3p">
                    <svg t="1706496592275" class="icon" viewBox="0 0 1664 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4257" width="24" height="24">
                      <path d="M0 0h1664v1024H0z" fill="#D71618" p-id="4258"></path>
                      <path
                        d="M268.8 411.52l102.4 74.24-39.04-120.32 102.4-74.88H307.84L268.8 169.6l-39.68 120.96H102.4l102.4 74.88-39.04 120.32 103.04-74.24zM569.6 188.8l47.36-11.52-44.8-18.56 3.2-48-30.72 37.12-45.44-18.56 25.6 40.96-30.72 37.12 46.72-11.52 25.6 40.96 3.2-48z m0 402.56l47.36-11.52-44.8-17.92 3.2-48.64-30.72 37.12-45.44-18.56 25.6 41.6-30.72 37.12 46.72-11.52L566.4 640l3.2-48.64z m112.64-277.12l48 7.68-33.92-34.56 22.4-42.88-43.52 21.76-33.28-35.2 6.4 48-42.88 21.76 47.36 8.32 7.04 48 22.4-42.88z m-14.72 173.44l39.68 26.88-16-45.44 37.76-30.08-48 1.92-16.64-46.08-13.44 46.72-48.64 1.92 40.32 26.88-13.44 46.72 38.4-29.44z"
                        fill="#FFF100"
                        p-id="4259"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex lang-space lh-30">
                    <span class="fs:lang">中文</span>
                  </div>
                </div>
              </div>
            </li>
            <li class="w-full" @click="changSmLanguage('en')">
              <div class="w-90 bd-1 py-2 margin-center">
                <div class="flex flex-row justify-end">
                  <div class="flex lang-svg px-3p py-3p">
                    <svg t="1706496627537" class="icon" viewBox="0 0 1536 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5661" width="24" height="24">
                      <path d="M0 0h1536v1024H0V0z" fill="#00458F" p-id="5662"></path>
                      <path d="M1536 358.4H921.6V0h-307.2v358.4H0v307.2h614.4V1024h307.2V665.6H1536v-307.2z" fill="#FFFFFF" p-id="5663"></path>
                      <path d="M1536 905.6L176 0H0v118.4L1360 1024H1536v-118.4z" fill="#FFFFFF" p-id="5664"></path>
                      <path d="M0 905.6L1360 0H1536v118.4L176 1024H0v-118.4z" fill="#FFFFFF" p-id="5665"></path>
                      <path d="M1536 422.4H857.6V0h-179.2v422.4H0v179.2h678.4V1024h179.2V601.6H1536v-179.2z" fill="#CC3440" p-id="5666"></path>
                      <path
                        d="M1536 1020.8v-80l-412.8-275.2h-121.6L1536 1020.8zM1536 0h-121.6L921.6 326.4v28.8h76.8L1536 0zM534.4 665.6L0 1020.8v3.2h115.2l496-329.6v-28.8h-76.8zM0 0v80l416 278.4h121.6L0 0z"
                        fill="#CC3440"
                        p-id="5667"
                      ></path>
                    </svg>
                  </div>
                  <div class="flex lang-space lh-30">
                    <span class="fs:lang"> English </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineStatus: false,
      lgLangStatus: false,
      smLangStatus: false,
      langCount: 0
    };
  },
  mounted() {
    console.log("KCMADE!!!!!mounted")
    //判断i18n来刷新对应的图标
    const currentLang = this.$i18n.locale;
    this.changLanguage(currentLang)

  },
  methods: {
    changeLineStatus: function () {
      if (this.lineStatus == false) {
        this.$refs.showLine.classList.add("hidden");
        this.$refs.closeLine.classList.remove("hidden");
        this.$refs.hiddenLine.classList.remove("hidden");
        this.lineStatus = true;
      } else {
        this.$refs.showLine.classList.remove("hidden");
        this.$refs.closeLine.classList.add("hidden");
        this.$refs.hiddenLine.classList.add("hidden");
        this.lineStatus = false;
      }
    },
    changLang: function () {
      if (this.lgLangStatus == false) {
        this.$refs.largeLangList.classList.remove("hidden");
        this.lgLangStatus = true;
      } else {
        this.$refs.largeLangList.classList.add("hidden");
        this.lgLangStatus = false;
      }
    },
    changLanguage: function (lang) {
      //根据lang的值来改变语言
      //如果当前的语言就是如此，就不必再次更新了
      const currentLang = this.$i18n.locale;
      this.langCount++;
      console.log(currentLang);
      // console.log(this.langCount);
      if (currentLang == lang) {
        console.log("current language is the same");
      } else {
        console.log("current language will be changed");
        this.$i18n.locale = lang;
      }
      if (lang == "zh") {
        this.$refs.enLargeIcon.classList.add("hidden");
        this.$refs.zhLargeIcon.classList.remove("hidden");
      } else {
        this.$refs.enLargeIcon.classList.remove("hidden");
        this.$refs.zhLargeIcon.classList.add("hidden");
      }
    },
    changSmLanguage: function (lang) {
      //根据lang的值来改变语言
      //如果当前的语言就是如此，就不必再次更新了
      const currentLang = this.$i18n.locale;
      this.langCount++;
      console.log(currentLang);
      console.log(this.langCount);
      // 更换语言
      if (currentLang == lang) {
        console.log("current language is zh");
      } else {
        this.$i18n.locale = lang;
      }
      // 点击中文或者英文切换icon图标
      if (lang == "zh") {
        this.$refs.enSmallIcon.classList.add("hidden");
        this.$refs.zhSmallIcon.classList.remove("hidden");
      } else {
        this.$refs.enSmallIcon.classList.remove("hidden");
        this.$refs.zhSmallIcon.classList.add("hidden");
      }
      // 点击之后隐藏语言栏
      this.changSmLang();
    },
    changSmLang: function () {
      if (this.smLangStatus == false) {
        this.$refs.smLangSpace.classList.remove("hidden");
        this.smLangStatus = true;
      } else {
        this.$refs.smLangSpace.classList.add("hidden");
        this.smLangStatus = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.global-header {
  width: 100%;
}

.vertical-line {
  border-left: 2px solid #000;
  height: 24px;
}

.bg-orange {
  background-color: orange;
}
.vline {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.z-50 {
  z-index: 50;
}

ul li {
  list-style-type: none;
}
ul {
  padding: 0;
  margin: 0;
}
.bd-1 {
  border-bottom: 1px solid #ffff;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}
.fs\:line {
  font-size: 18px;
}

.lang-ui {
  margin: 0;
  padding: 0;
}

.lang-li {
  list-style-type: none;
}
.fs\:lang {
  font-size: 16px;
}

.lang-svg {
  width: 30px;
  height: 30px;
}
.lang-space {
  width: 80px;
  height: 30px;
}
.px-3p {
  padding-left: 3px;
  padding-right: 3px;
}
.py-3p {
  padding-top: 3px;
  padding-bottom: 3px;
}
.lh-30 {
  line-height: 30px;
}
.left-n15 {
  left: -15px;
}
.bd-1p {
  border: 1px solid #e8e8e8;
}
.top-150 {
  top: 150%;
}
.pointer {
  cursor: pointer;
}
.px-1p {
  padding-left: 1px;
  padding-right: 1px;
}
.py-1p {
  padding-top: 1px;
  padding-bottom: 1px;
}
@media (max-width: 744px) {
  .sml\:hidden {
    display: none;
  }
}

@media (min-width: 744px) {
  .lgl\:hidden {
    display: none;
  }
}
</style>
