<template>
  <div class="flex grow">
    <div class="container flex-col items-center" style="padding-top: 80px;">
      <!--三个介绍-->
      <div class="w-full grid grid-cols-12 gap-2 mt-4 pb-4">
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div class="w-full p-4">
            <div class="flex pb-4" style="justify-content: center;">
              <!-- <span class="text-black fs-24">Producting is no longer a waiting game</span> -->
              <span class="text-white cfs bold-700">{{ $t("front.intro.first.tl1") }}</span>&nbsp;
              <span class="text-white cfs">{{ $t("front.intro.first.tl2") }}</span>
            </div>
            <div class="flex justify-center margin-center pt-2">
              <span class="inline-block w-full text-white fs-15">{{ $t("front.intro.first.tx1") }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-span-1 hidden lg:show"></div> -->
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div class="p-4">
            <div class="flex pb-4" style="justify-content: center;">
              <span class="text-white cfs bold-700">{{ $t("front.intro.second.tl1") }}</span>&nbsp;
              <span class="text-white cfs">{{ $t("front.intro.second.tl2") }}</span>
            </div>
            <div class="flex  justify-center margin-center pt-2">
              <span class="inline-block w-full text-white fs-15">{{ $t("front.intro.second.tx1") }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="col-span-1 hidden lg:show"></div> -->
        <div class="col-span-12 md:col-span-6 lg:col-span-4">
          <div class="p-4">
            <div class="flex pb-4" style="justify-content: center;">
              <span class="text-white cfs bold-700">{{ $t("front.intro.third.tl1") }}</span>&nbsp;
              <span class="text-white cfs">{{ $t("front.intro.third.tl2") }}</span>
            </div>
            <div class="flex  justify-center margin-center pt-2">
              <span class="inline-block w-full text-white fs-15">{{ $t("front.intro.third.tx1") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontHeaderModule",
  data() {
    return {};
  }
};
</script>
<style scoped>
.grid {
  display: grid;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

@media (min-width: 744px) {
  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }
}

.mt-8 {
  margin-top: 2rem;
}

.pb-16 {
  padding-bottom: 4rem;
}
.margin-center {
  margin: 0 auto;
}
.cfs {
  font-size: 20px;
}
@media (min-width: 744px) {
  .cfs {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .cfs {
    font-size: 26px;
  }
}

@media (min-width: 1440px) {
  .cfs {
    font-size: 30px;
  }
}
.p-4 {
  padding: 1rem;
}

.bold-700 {
  font-weight: 700;
}

.text-gray-161 {
  background-color: (161, 161, 161);
}

.text-pink {
  color: #af27a2;
}
.text-light-pink {
  color: #db36ca;
}

.text-white {
  color: #ffffff;
}
</style>
