<template>
  <div class="w-full flex grow">
    <div class="container w-full" style="padding-top: 80px;">
      <!--标题介绍-->
      <div class="header-title pt-6 text-left">
        <div class="w-50 flex flex-col">
          <div class="flex w-full">
            <span class="flex text-white" style="font-size: 70px;">{{ $t("front.title1") }}</span>
          </div>
          <div class="flex w-full">
            <span class="text-white fs:title ">{{ $t("front.title2") }}</span>
          </div>
        </div>
      </div>
      <!--具体详情-->
      <div class="header-title pt-6 text-left flex" style="padding-top: 50px;">
        <div class="flex flex-col h-full">
          <div class="flex w-full pb-1">
            <span class="text-white fs:tt">{{ $t("front.desc1") }}</span>
          </div>
          <div class="flex w-full pb-1">
            <span class="text-white fs:tt">{{ $t("front.desc2") }}</span>
          </div>
          <div class="flex w-full pb-1">
            <span class="text-white fs:tt">{{ $t("front.desc3") }}</span>
          </div>
        </div>
      </div>
      <!--按钮部分-->
      <div class="flex flex-row items-center justify-left hr-16 mt-6" style="padding-top: 50px;">
        <router-link tag="a" to="/about" class="flex">
          <button class="id-bt cursor-pointer"><span class="text-white fs:tt">{{ $t("front.download") }}</span></button>
        </router-link>
        <div class="flex text-center h-full pt-5 pl-4">
          <!-- <span class="text-pink fs:tt"> Learn More &#10140;</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight //实时屏幕高度
    };
  },
  created() {
    // console.log("windowHeight", this.windowHeight);
    console.log(window.innerHeight);
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor:pointer
}

.fs\:title {
  font-size: 18px;
}
.fs\:tt {
  font-size: 16px;
}

.id-bt {
  width: 120px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #db36ca;
  background-color: transparent;
}
.text-pink {
  color: #af27a2;
}

@media (min-width: 744px) {
  .fs\:title {
    font-size: 24px;
  }
  .fs\:tt {
    font-size: 18px;
  }

  .id-bt {
    width: 140px;
    height: 40px;
    border-radius: 3px;
    border: 2px solid #db36ca;
    background-color: transparent;
  }
}

@media (min-width: 1024px) {
  .fs\:title {
    font-size: 30px;
  }
  .fs\:tt {
    font-size: 20px;
  }

  .id-bt {
    width: 160px;
    height: 50px;
    border-radius: 3px;
    border: 3px solid #db36ca;
    background-color: transparent;
  }
}

@media (min-width: 1440px) {
  .fs\:title {
    font-size: 40px;
  }
  .fs\:tt {
    font-size: 24px;
  }

  .id-bt {
    width: 230px;
    height: 64px;
    border-radius: 3px;
    border: 3px solid #db36ca;
    background-color: transparent;
  }
}
</style>
