<template>
  <div class="global-footer relative">
    <div class="container pt-16">
      <!--尾部各种文档链接-->
      <div class="w-full flex flex-row flex-wrap">
        <!--左边文档部分 -->
        <div class="flex flex-row flex-wrap flg:width">
          <div class="flex flex-2 py-2">
            <div class="flex flex-col items-center">
              <div class="flex w-full px-2 pt-2 pb-4">
                <span class="text-foot fs:fte">{{ $t("footer.resources.title")}}</span>
              </div>
              <a href="https://projects.eclipse.org/projects/automotive.leda" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.resources.leda")}}</span>
              </a>
              <a href="https://projects.eclipse.org/projects/automotive.kuksa" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.resources.kuksa")}}</span>
              </a>
              <a href="https://projects.eclipse.org/projects/automotive.velocitas" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.resources.velocitas")}}</span>
              </a>
            </div>
          </div>

          <div class="flex flex-2 py-2">
            <div class="flex flex-col items-center">
              <div class="flex w-full px-2 pt-2 pb-4">
                <span class="text-foot fs:fte">{{ $t("footer.legal.title")}}</span>
              </div>
              <router-link tag="a"  to="/terms" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.legal.terms")}}</span>
              </router-link>
              <router-link tag="a"  to="/privacy" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.legal.privacy")}}</span>
              </router-link>
            </div>
          </div>
          <div class="flex flex-2 py-2">
            <div class="flex flex-col items-center">
              <div class="flex w-full px-2 pt-2 pb-4">
                <span class="text-foot fs:fte">{{ $t("footer.links.title")}}</span>
              </div>
              <a href="https://www.bosch.com.cn/products-and-services/mobility/" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.links.bosch")}}</span>
              </a>
              <a href="https://www.eclipse.org/org/" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.resources.eclipse")}}</span>
              </a>
              <a href="https://www.etas.cn/" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.links.etas")}}</span>
              </a>
              <a href="https://covesa.global/" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.links.covesa")}}</span>
              </a>
              <a href="https://www.autosar.org/" target="_blank" rel="noopener" class="flex w-full px-2 py-2">
                <span class="text-foot fs:ftt">{{ $t("footer.links.autosar")}}</span>
              </a>
            </div>
          </div>
        </div>
        <!--右边二维码部分-->
        <div class="flex w-25 justify-center fsm:full">
          <div class="flex flex-col">
            <div class="flex py-2 w-full items-center">
              <span class="text-foot w-full fs:fte">{{ $t("footer.news.title")}}</span>
            </div>
            <div class="flex py-2 justify-center">
                <img src="@/assets/images/concact.png" alt="concact" width="100%" height="100%" style="padding: 20px;" />
            </div>
            <div class="flex py-2 justify-center">
              <span class="text-foot fs:ftt">{{ $t("footer.news.scanqr")}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full">
        <div class="w-full py-4">
          <span class="text-foot" style="font-size: 15px;"> © 2023 ETAS GmbH </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.global-footer {
  width: 100%;
  background-color: #202337;
}

.text-foot {
  color: #a3a3a3;
}

.flg\:width {
  width: 75%;
}

@media (max-width: 743px) {
  .flg\:hidden {
    display: none;
  }
  .fsm\:full {
    width: 100%;
  }
}

.bg-og {
  background-color: orange;
}
.bg-pk {
  background-color: pink;
}
.flex-2 {
  flex-basis: 174px;
  flex-grow: 1;
  flex-shrink: 1;
}
.fs\:fte {
  font-size: 10px;
}
.fs\:ftt {
  font-size: 16px;
}

@media (min-width: 1024px) {
  .fs\:fte {
    font-size: 10px;
  }
  .fs\:ftt {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .fs\:fte {
    font-size: 24px;
  }
  .fs\:ftt {
    font-size: 15px;
  }
}

.concact-size {
  width: 200px;
  height: 200px;
}

@media (min-width: 744px) {
  .concact-size {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1024px) {
  .concact-size {
    width: 220px;
    height: 220px;
  }
}

@media (min-width: 1440px) {
  .concact-size {
    width: 240px;
    height: 240px;
  }
}
</style>
