import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 引入自定义配置

import zh from './langs/zh-CN'
import en from './langs/en-US'

// 全局注册国际化包
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || "zh", //将语言标识存入localStorage或者sessionStorage中, 页面刷新不会默认中文显示

    messages: {
        // 语言包
        'zh': {
            ...zh
        },
        'en': {
            ...en
        }
    },
    silentTranslationWarn: true, //解决vue-i18n黄色警告"value of key 'xxx' is not a string"和"cannot translate the value of keypath 'xxx'.use the value of keypath as default",可忽略
    globalInjection: true, // 全局注入
    fallbackLocale: 'en', // 指定的locale没有找到对应的资源或当前语种不存在时，默认设置当前语种为中文
});

export default i18n
