<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <HomeDetail></HomeDetail>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HomeDetail from "@/components/HomeDetail/HomeDetail.vue";

export default {
  name: "HomeView",
  components: {
    HomeDetail
  }
};
</script>
