<template>
  <div class="w-full">
    <global-header></global-header>
    <home-header></home-header>
    <home-movie></home-movie>
    <home-version></home-version>
    <global-footer></global-footer>
  </div>
</template>
<script>
import GlobalFooter from "../GlobalFooter.vue";
import GlobalHeader from "../GlobalHeader.vue";
import HomeHeader from "./HomeHeader/HomeHeader.vue";
import HomeMovie from "./HomeMovie/HomeMovie.vue";
import HomeVersion from "./HomeVersion/HomeVersion.vue";
export default {
  components: { GlobalHeader, GlobalFooter, HomeHeader, HomeVersion, HomeMovie }
};
</script>

<style lang="css"></style>
