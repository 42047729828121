import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
// 引入css
import '@/assets/styles/general.css'
import '@/assets/styles/global.css'

Vue.config.productionTip = false

Vue.directive('title',{inserted:function(el,binding){
  document.title = el.dataset.title
}})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
