<template>
  <div class="home-header">
    <div class="banner   home-banner">
      <div class="banner w-full h-full relative">
        <div class="absolute w-full h-full pointer-events-none">
          <div class="absolute lb"></div>
          <div class="absolute rt">
            <div class="rtb w-full h-full"></div>
          </div>
          <div class="absolute rp"></div>
          <div class="absolute rb"></div>
        </div>
        <div class="w-full min-h flex flex-col justify-stretch">
          <home-header-text></home-header-text>

          <home-header-icon></home-header-icon>

          <home-header-intro></home-header-intro>

          <home-header-marketIntro></home-header-marketIntro>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHeaderIntro from "./HomeHeaderIntro.vue";
import HomeHeaderText from "./HomeHeaderText.vue";
import HomeHeaderIcon from "./HomeHeaderIcon.vue";
import HomeHeaderMarketIntro from "./HomeHeaderMarketIntro.vue"
export default {
  name: "HomeHeader",
  data() {
    return {};
  },
  components: { HomeHeaderText, HomeHeaderIcon, HomeHeaderMarketIntro, HomeHeaderIntro }
};
</script>

<style lang="css">
.pointer-events-none {
  pointer-events: none;
}

.home-header {
  width: 100%;
  background-image: linear-gradient(180deg, #000000 0%, #282828 100%);
}

.home-banner {
  background-image: linear-gradient(180deg, #000000 0%, #282828 100%);
}

.header-gradient {
  background-image: url("@/assets/images/banner.png"), linear-gradient(180deg, #000000 0%, #282828 100%);
  /* background-color: #000000; */
  /* background-size: contain; */
  /* background: url("@/assets/images/banner.png"), lightgray 50% / cover no-repeat; */
  background-blend-mode: soft-light;
}

.banner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 744px) {
  .banner {
    width: 744px;
  }
}

@media (min-width: 1024px) {
  .banner {
    width: 1024px;
  }
  .home-height {
    height: calc(100vh - 4rem);
  }
  .min-h {
    min-height: calc(100vh - 4rem);
  }
}
@media (min-width: 1440px) {
  .banner {
    width: 1440px;
  }
  .home-height {
    height: calc(100vh - 4rem);
  }
  .min-h {
    min-height: calc(100vh - 4rem);
  }
}

.bg-orange {
  background-color: orange;
}
/* .lb {
  left: 0;
  top: 0;
  width: 740px;
  height: 560px;
  background-image: linear-gradient(180deg, #000000 0%, #282828 100%);
  opacity: 0.5;
}
.rt {
  width: 700px;
  height: 700px;
  top: -350px;
  right: 0;
  border-radius: 9999px;
}
.rtb {

  border-radius: 700px;
  background: rgba(255, 107, 0, 0.5);
  filter: blur(100px);
}  */

/* .rp {
  top: 120px;
  left: 570px;
  width: 300px;
  height: 300px;
  border-radius: 9999px;
  box-shadow: 2px 2px 3px 3px #df2aa18f;

  background-color: rgba(223, 42, 161, 0.56);
  filter: blur(50px);
} */

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 20;
}
/* .rb {
  top: 350px;
  right: 300px;
  width: 300px;
  height: 300px;
  border-radius: 400px;
  background: rgba(28, 81, 178, 0.6);
  filter: blur(50px);
} */

@media (min-width: 1024px) {
  .lb {
    left: 0;
    top: 0;
    width: 540px;
    height: 260px;
  }

  .rt {
    width: 400px;
    height: 400px;
    top: -200px;
    right: 0;
    border-radius: 9999px;
  }
  .rtb {
    border-radius: 200px;
    background: rgba(255, 107, 0, 0.5);
    filter: blur(50px);
  }
  .rp {
    top: 120px;
    left: 450px;
    width: 300px;
    height: 300px;
    border-radius: 150px;
    box-shadow: 2px 2px 3px 3px #df2aa18f;

    background-color: rgba(223, 42, 161, 0.56);
    filter: blur(50px);
  }
  .rb {
    top: 350px;
    right: 200px;
    width: 200px;
    height: 200px;
    border-radius: 100px;
    background: rgba(28, 81, 178, 0.6);
    filter: blur(50px);
  }
}

@media (min-width: 1440px) {
  .lb {
    left: 0;
    top: 0;
    width: 740px;
    height: 560px;
    /* background-image: linear-gradient(180deg, #000000 0%, #282828 100%);
  opacity: 0.5; */
  }
  .rt {
    width: 700px;
    height: 700px;
    top: -350px;
    right: 0;
    border-radius: 9999px;
  }
  .rtb {
    /* background-image: linear-gradient(-45deg, #ff6b0080 0%, #ff6b0080 70%, #000000 100%);
  filter: blur(3px); */
    border-radius: 700px;
    background: rgba(255, 107, 0, 0.5);
    filter: blur(100px);
  }
  .rp {
    top: 120px;
    left: 570px;
    width: 300px;
    height: 300px;
    border-radius: 9999px;
    box-shadow: 2px 2px 3px 3px #df2aa18f;

    background-color: rgba(223, 42, 161, 0.56);
    filter: blur(50px);
  }

  .z-1 {
    z-index: 1;
  }
  .z-2 {
    z-index: 20;
  }
  .rb {
    top: 350px;
    right: 300px;
    width: 300px;
    height: 300px;
    border-radius: 400px;
    background: rgba(28, 81, 178, 0.6);
    filter: blur(50px);
  }
}
</style>
