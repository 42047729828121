<template>
  <div class="flex flex-row w-full flex-wrap justify-between">
    <!-- <div style="display: flex; justify-content: space-between; align-items: flex-start; padding-top: 100px;"> -->
    <!-- 左侧容器：满足汽车跨域开发 文字 -->
    <!-- <div style="flex: 1; max-width: calc(50% - 80px); margin-right: 80px;"> -->
    <div class="flex flex-row flex-wrap justify-between center v-width mb-8">
      <div class="v-width50">
        <h1 style="font-size: 32px; text-align: center;  color: #ffffff;">
          {{ $t("motivation.intro.first.title") }}
        </h1>
        <p style="font-size: 16px; line-height: 1.5; color: #ffffff;text-align: center;padding: 5rem;">
          {{ $t("motivation.intro.first.subtitle") }}
        </p>
      </div>
      <div class="v-width50">
        <!-- AICar 图片 -->
        <img src="@/assets/images/AICar.png" style="padding: 1rem;width: 100%;" />
      </div>
    </div>

    <!-- 右侧容器：缩短产品上市周期 文字和 AICar 图片 -->
    <div class="flex flex-row flex-row-reverse flex-wrap justify-between center v-width mb-8">
      <div class="v-width50">
        <h1 style="font-size: 32px; text-align: center;  color: #ffffff;">
          {{ $t("motivation.intro.second.title") }}
        </h1>
        <p style="font-size: 16px; line-height: 1.5; color: #ffffff;text-align: center;padding: 5rem;">
          {{ $t("motivation.intro.second.subtitle") }}
        </p>
      </div>
      <div class="v-width50">
        <!-- AICar 图片 -->
        <img src="@/assets/images/AICustomer.png" style="padding: 1rem;width: 100%;" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HomeHeaderMarketIntro",
  data() {
    return {
      motivation1: {
        title: "满足汽车跨域开发",
        line1: "当今汽车技术飞速发展，车载系统的复杂性和功能需求不断增",
        line2: "加。传统的硬件中心设计模式已无法满足汽车跨域开发的需",
        line3: "求。软件定义汽车中间件技术应运而生，为汽车跨域开发提供",
        line4: "了新的解决方案。它将车载系统的硬件与软件进行解耦，大大",
        line5: "提高了软件的可移植性和可扩展性。"
      },
      shortenProductCycle: {
        title: "缩短产品上市周期",
        line1: "当今汽车行业日新月异，客户对车辆功能的需求日益增加。然",
        line2: "而，依靠传统的开发方式来开发ECU功能，其效率和响应速度",
        line3: "已经跟不上市场的变化。这一问题日益凸显，需要寻找更加高",
        line4: "效的解决方案。"
      }
    };
  }
};
</script>

<style scoped>
/* 这里可以添加一些组件特有的样式 */
.v-width {
  width: 100vw;
}

@media (min-width: 375px) {
  .v-width50 {
    width: 100%;
  }
}

@media (min-width: 430px) {
  .v-width50 {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .v-width50 {
    width: 50%;
  }
}
</style>