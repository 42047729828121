<template>
  <!--最外层图标div-->
  <!--设置内上边距 下内边距-->
  <!-- <div class="container grow zs:py" > -->

    <div class="flex row flex-wrap center items-center justify-around" style="margin-top: 5rem;">

      <div class="mx-1 v-width50">
        <img src="@/assets/images/bosch_logo.png" alt="ecl" width="100%"/>
        </div>

        <div class="mx-1 v-width50">
          <img src="@/assets/images/ecl.png" alt="ecl" width="100%" />
        </div>

        <div class="mx-1 v-width50">
          <img src="@/assets/images/etas_logo.svg" alt="ecl" width="100%" />
        </div>

        <div class="mx-1 v-width50">
          <img src="@/assets/images/covesa-logo.png" alt="ecl" width="100%"/>
        </div>

        <div class="mx-1 v-width50">
          <img src="@/assets/images/al.png" alt="ecl" width="100%"/>
        </div>

    <!-- </div> -->

    </div>
</template>

<script>
export default {
  name: "HomeHeaderIcon",
  data() {
    return {};
  }
};
</script>

<style scoped>
.bg-test {
  background-color: bisque;
}
.sicon {
  width: 100%;
  height: 70px;
}
.fbe {
  filter: brightness(10%);
}

.zs\:py {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.v-width50 {
    width: 30%;
    padding: 1rem;
  }
  
@media (min-width: 375px) {
  .v-width50 {
    width: 30%;
    padding: 1rem;
  }
}

@media (min-width: 430px) {
  .v-width50 {
    width:30%;
    padding: 1rem;
  }
}

@media (min-width: 1024px) {
  .v-width50 {
    width: 160px;
  }
}

@media (min-width: 532px) {
  .sicon {
    width: 250px;
    height: 70px;
  }
  .zs\:py {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 744px) {
  .sicon {
    width: 232px;
    height: 70px;
  }
  .zs\:py {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .sicon {
    width: 240px;
    height: 70px;
  }
  .zs\:py {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

@media (min-width: 1440px) {
  .sicon {
    width: 262.4px;
    height: 70px;
  }
  .zs\:py {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.icf {
  width: 200px;
  height: 60px;
}

.st-size {
  width: 160px;
  height: 36px;
}

.pd-st {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.pd-em {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.em-size {
  width: 160px;
  height: 40px;
}

.mr-center {
  margin: 0 auto 10px;
}

.es-size {
  width: 148px;
  height: 41px;
}

.pd-es {
  padding-left: 26px;
  padding-right: 26px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}
</style>
