<template>
  <div class="tv-container relative bg-te tv-py" ref="tv">
    <div class="container relative h-full">
      <div class="relative w-full h-full object-cover" v-if="windowWidth > 744">
        <video width="100%" height="100%" autoplay muted webkit-playsinline="true" id="videoPlayer" ref="videoPlayer"
          @ended="endMovie($event.target)" @play="startMovie($event.target)" @click="conClick" :src="initSrc">
          <!-- <source :src="initSrc" type="video/mp4" /> -->
        </video>

      </div>
      <div class="relative w-full h-full object-cover" @touchstart="touchStart($event)" @touchmove="touchMove($event)"
        @touchend="touchendFun($event)" v-else>
        <video width="100%" height="100%" autoplay muted webkit-playsinline="true" id="videoPlayer" ref="videoPlayer"
          @ended="endMovie($event.target)" :src="initSrc" @click="showClick" @play="startMobMovie($event.target)">
          <source :src="initSrc" type="video/mp4" />
        </video>
      </div>
      <div class="absolute container left-0 top-0 h-full">
        <div class="w-full h-full bg-mask"></div>
      </div>
      <div class="absolute left-0 top-0 h-full container" @touchstart="touchStart($event)"
        @touchmove="touchMove($event)" @touchend="touchendFun($event)">
        <div class="w-full h-full flex flex-col justify-end" style="background-color: rgba(9, 9, 9, 0.5);">
          <!-- <div class="w-full h-full flex-1 pt-12 pb-8 text-center" style="background-color: aqua;"> -->
          <!-- <span class="text-white mv-ts">{{ $t("mv.title")}}</span> -->
          <!-- </div> -->
          <div class=" justify-around py-10 mb-8 pl">
            <div class=" flex-row justify-around text-left">
              <div v-if="videoIndex == 0" class="desktop text-white fs:mi">{{ $t("mv.comfort_entry") }}</div>
              <div v-else-if="videoIndex == 1" class="desktop text-white fs:mi">{{ $t("mv.lighting_show") }}</div>
              <div v-else-if="videoIndex == 2" class="desktop text-white fs:mi">{{ $t("mv.suspension_system") }}</div>
              <div v-else class="desktop text-white fs:mi">{{ $t("mv.comfort_entry") }}</div>
              <span v-if="videoIndex == 0" class="desktop text-white fs:mi2 w-40">{{ $t("mv.comfort_entry_desc")
                }}</span>
              <span v-else-if="videoIndex == 1" class="desktop text-white fs:mi2 w-40">{{
        $t("mv.lighting_show_desc") }}</span>
              <span v-else-if="videoIndex == 2" class="desktop text-white fs:mi2 w-40">{{
        $t("mv.suspension_system_desc") }}</span>
        <span v-else class="desktop text-white fs:mi2 w-40">{{ $t("mv.comfort_entry_desc")
                }}</span>
            </div>
          </div>
          <div class="flex items-center ">
            <div class="flex w-full justify-center items-center hr-22">
              <div class="marker relative tfp bfp" ref="firstNode" @click="changeTv($event, 0)">
                <!-- <div class="absolute node-intro text-ta" ref="nodeIntro1">{{ $t("mv.comfort_entry") }}</div> -->
              </div>
              <div class="tline"></div>
              <div class="marker relative tfp bfp" ref="secondNode" @click="changeTv($event, 1)">
                <!-- <div class="absolute node-intro text-ta" ref="nodeIntro2">{{ $t("mv.lighting_show") }}</div> -->
              </div>
              <div class="tline"></div>
              <div class="marker relative tfp bfp" ref="thirdNode" @click="changeTv($event, 2)">
                <!-- <div class="absolute node-intro text-ta" ref="nodeIntro3">{{ $t("mv.suspension_system") }}</div> -->
              </div>
            </div>
          </div>
          <!-- <div class="h-full flex-1 w-full flex items-center tlg:hidden">
            <div class="w-80 margin-center flex flex-row justify-center gap-4">
              <div class="point" ref="mobFirstNode"></div>
              <div class="point" ref="mobSecondNode"></div>
              <div class="point" ref="mobThirdNode"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vd1 from "@/assets/movies/1.mp4";
import vd2 from "@/assets/movies/2.mp4";
import vd3 from "@/assets/movies/3.mp4";

export default {
  name: "HomeMovie",
  data() {
    return {
      videos: [vd1, vd2, vd3],
      initSrc: vd1,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      startX: 0,
      moveX: 0,
      videoIndex: 0
    };
  },
  created() {
    console.log(this.windowWidth);
  },

  methods: {
    playNextVideo: function () {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play();
      } else {
        this.$refs.videoPlayer.load();
        this.$refs.videoPlayer.play();
      }
    },
    startMovie: function (e) {
      const currentSrc = e.currentSrc;
      const fullPathArray = currentSrc.split("/");
      const lastPath = fullPathArray.pop();
      const mediaPath = fullPathArray.pop();
      const targetSrc = "/web-pro/" + mediaPath + "/" + lastPath;
      console.log("play方法开始");
      console.log("targetSrc", targetSrc);
      console.log(this.videos);
      const index = this.videos.indexOf(targetSrc);

      console.log("index:", index);
      this.videoIndex = index
      const firstNode = this.$refs.firstNode;
      const firstNodeTro = this.$refs.nodeIntro1;
      const secondNode = this.$refs.secondNode;
      const secondNodeTro = this.$refs.nodeIntro2;
      const thirdNode = this.$refs.thirdNode;
      const thirdNodeTro = this.$refs.nodeIntro3;

      const unSelectedColor = "#6f6f6f"
      const selectedColor = "#ffffff"

      if (index == 0) {
        firstNode.style.backgroundColor = selectedColor;

        secondNode.style.backgroundColor = unSelectedColor;
        thirdNode.style.backgroundColor = unSelectedColor;
        firstNodeTro.style.color = selectedColor;
        secondNodeTro.style.color = unSelectedColor;
        thirdNodeTro.style.color = unSelectedColor;

      } else if (index == 1) {
        firstNode.style.backgroundColor = unSelectedColor;
        secondNode.style.backgroundColor = selectedColor;
        thirdNode.style.backgroundColor = unSelectedColor;
        firstNodeTro.style.color = unSelectedColor;
        secondNodeTro.style.color = selectedColor;
        thirdNodeTro.style.color = unSelectedColor;
      } else if (index == 2) {
        firstNode.style.backgroundColor = unSelectedColor;
        secondNode.style.backgroundColor = unSelectedColor;
        thirdNode.style.backgroundColor = selectedColor;

        firstNodeTro.style.color = unSelectedColor;
        secondNodeTro.style.color = unSelectedColor;
        thirdNodeTro.style.color = selectedColor;
      } else {
        firstNode.style.backgroundColor = unSelectedColor;
        secondNode.style.backgroundColor = unSelectedColor;
        thirdNode.style.backgroundColor = unSelectedColor;
      }
    },
    startMobMovie: function (e) {
      const currentSrc = e.currentSrc;
      const fullPathArray = currentSrc.split("/");
      const lastPath = fullPathArray.pop();
      const mediaPath = fullPathArray.pop();
      const targetSrc = "/web-pro/" + mediaPath + "/" + lastPath;
      const index = this.videos.indexOf(targetSrc);
      const mobFirstNode = this.$refs.mobFirstNode;
      const mobSecondNode = this.$refs.mobSecondNode;
      const mobThirdNode = this.$refs.mobThirdNode;

      if (index == 0) {
        mobFirstNode.style.backgroundColor = "#C0C0C0";
        mobSecondNode.style.backgroundColor = "#3a3a3a";
        mobThirdNode.style.backgroundColor = "#3a3a3a";
      } else if (index == 1) {
        mobFirstNode.style.backgroundColor = "#3a3a3a";
        mobSecondNode.style.backgroundColor = "#C0C0C0";
        mobThirdNode.style.backgroundColor = "#3a3a3a";
      } else if (index == 2) {
        mobFirstNode.style.backgroundColor = "#3a3a3a";
        mobSecondNode.style.backgroundColor = "#3a3a3a";
        mobThirdNode.style.backgroundColor = "#C0C0C0";
      }
    },
    endMovie: function (e) {
      const currentSrc = e.currentSrc;
      const fullPathArray = currentSrc.split("/");
      const lastPath = fullPathArray.pop();
      const mediaPath = fullPathArray.pop();
      const targetSrc = "/web-pro/" + mediaPath + "/" + lastPath;
      const index = this.videos.indexOf(targetSrc);
      if (index < this.videos.length - 1) {
        console.log("111");
        var res = this.videos[index + 1];
        console.log("targetSrc", targetSrc);
        console.log("res", res);
        videoPlayer.src = res;
        console.log("videoPlayer.currentSrc", videoPlayer.currentSrc);
        this.playNextVideo();
      } else {
        videoPlayer.src = this.videos[0];
        this.playNextVideo();
      }
    },
    changeTv: function (e, index) {
      console.log("video:start click");
      console.log("video:" + e);
      console.log("video:" + index);
      console.log(this.$refs.videoPlayer);
      this.videoIndex = index;
      this.$refs.videoPlayer.pause();
      if (index == 0) {
        this.$refs.videoPlayer.src = vd1;
      } else if (index == 1) {
        this.$refs.videoPlayer.src = vd2;
      } else if (index == 2) {
        this.$refs.videoPlayer.src = vd3;
      }
      this.$refs.videoPlayer.play();
    },
    touchStart: function (event) {
      console.log("00000");
      // console.log(event.touches);
      this.startX = event.touches[0].pageX;
      // console.log("startX", this.startX);
    },
    touchMove: function (event) {
      console.log("1111");
      // console.log(event);
      this.moveX = event.touches[0].pageX - this.startX;
    },
    touchendFun: function (event) {
      console.log("22222");
      console.log(event);
      const currentSrc = this.$refs.videoPlayer.currentSrc;
      const fullPathArray = currentSrc.split("/");
      const lastPath = fullPathArray.pop();
      const mediaPath = fullPathArray.pop();
      const targetSrc = "/web-pro/" + mediaPath + "/" + lastPath;
      console.log("targetSrc", targetSrc);
      console.log(this.videos);
      let index = this.videos.indexOf(targetSrc);
      let finalSrc = "";
      if (this.moveX < -30) {
        console.log("向左滑动事件被触发！");
        console.log("index: ", index);
        let targetIndex = index - 1;
        console.log("targetIndex", targetIndex);
        if (targetIndex < 0) {
          index = 2;
          finalSrc = this.videos[2];
        } else {
          finalSrc = this.videos[index - 1];
        }
        videoPlayer.src = finalSrc;
        this.playNextVideo();
      } else if (this.moveX > 30) {
        console.log("向右滑动事件被触发！");
        let targetIndex = index + 1;
        console.log("targetIndex", targetIndex);
        if (targetIndex > 2) {
          index = 0;
          finalSrc = this.videos[0];
        } else {
          finalSrc = this.videos[targetIndex];
        }
        videoPlayer.src = finalSrc;
        this.playNextVideo();
      }
    },
    conClick: function () {
      console.log("good");
    },
    clickStop: function () {
      console.log("禁止传递事件");
    },
    showClick: function () {
      console.log("click");
    }
  }
};
</script>
<style scoped>
.tv-container {
  width: 100%;
  height: 400px;
}

.bg-te {
  background-color: #282828;
}

.tl\:show {
  display: none;
}

.bg-gray {
  background-color: gray;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.bg-torange {
  background-color: orange;
}

.object-cover video {
  -o-object-fit: cover;
  object-fit: cover;
}

.tline {
  display: inline-block;
  width: 26%;
  height: 4px;
  background-color: #3a3a3a;
}

.marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  background-color: #3a3a3a;
}

.height-20p {
  height: 20px;
}

.tfp {
  top: 4px;
}

.bfp {
  bottom: 4px;
}

.t8p {
  top: 8px;
}

.b8p {
  bottom: 8px;
}

.intro-text {
  width: 200px;
  height: 160px;
  bottom: 20px;
  left: -50px;
}

.fs-tt {
  font-size: 14px;
}

.mb\:fs {
  font-size: 15px;
}

.mb-ele {
  padding-top: 10%;
}

.points {
  height: 16px;
}

.point {
  width: 8px;
  height: 8px;
  background-color: #3a3a3a;
  border-radius: 9999px;
  display: inline-block;
  margin-right: 10px;
}

.tv-py {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mv-ts {
  font-size: 1.25rem;
}

/* @media (max-width: 743px) {
  .mb\:show {
    display: block;
  }
} */

/* @media (min-width: 744px) {
  .tl\:show {
    display: block;
  }
  .marker {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 9999px;
    background-color: #3a3a3a;
  }
  .tline {
    display: inline-block;
    width: 28%;
    height: 5px;
    background-color: #3a3a3a;
  }
  .lbp {
    bottom: 1.5px;
  }
  .node-intro {
    top: 16px;
    font-size: 17px;
    width: 120px;
    height: 22px;
    text-align: center;
    left: -60px;
  }
  .intro-text {
    width: 210px;
    height: 200px;
    bottom: 40px;
    left: -70px;
  }
  .fs-tt {
    font-size: 16px;
  }
  .tv-py {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .mv-ts {
    font-size: 1.5rem;
  }
  .tv-container {
    width: 100%;
    height: 450px;
  }
} */

@media (max-width: 375px) {
  .mobile {
    display: inherit;
  }

  .desktop {
    display: none;
  }

  .pl {
    padding-left: 1rem;
  }

  /* py-10 mb-8 pl-24 */
  /* .py{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mb-8{
  margin-bottom: 1rem;
}

.pl-24{
  padding-left: 1rem;
} */

  .tl\:show {
    display: block;
  }

  .marker {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    background-color: #3a3a3a;
  }

  .tfp {
    top: 2px;
  }

  .bfp {
    bottom: 2px;
  }

  .tline {
    display: inline-block;
    width: 30%;
    height: 6px;
    background-color: #3a3a3a;
  }

  .lbp {
    bottom: 3px;
  }

  .node-intro {
    top: 30px;
    font-size: 19px;
    width: 130px;
    height: 26px;
    text-align: center;
    left: -65px;
  }

  .intro-text {
    width: 260px;
    height: 200px;
    bottom: 40px;
    left: -110px;
  }

  .fs-tt {
    font-size: 18px;
  }

  .tv-py {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .mv-ts {
    font-size: 2rem;
  }

  .tv-container {
    width: 100%;
    height: 100vh;
  }
  .fs\:mi {
    font-size: 3rem;
  }

  .fs\:mi2 {
    font-size: 0.5rem;
  }
}

@media (min-width: 431px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: inherit;
  }

  .pl {
    padding-left: 1rem;
  }

  .fs\:mi {
    font-size: 1rem;
  }

  .fs\:mi2 {
    font-size: 0.5rem;
  }
}

@media (min-width: 1024px) {

  .pl {
    padding-left: 5rem;
  }

  .tl\:show {
    display: block;
  }

  .marker {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    background-color: #3a3a3a;
  }

  .tfp {
    top: 2px;
  }

  .bfp {
    bottom: 2px;
  }

  .tline {
    display: inline-block;
    width: 30%;
    height: 6px;
    background-color: #3a3a3a;
  }

  .lbp {
    bottom: 3px;
  }

  .node-intro {
    top: 30px;
    font-size: 19px;
    width: 130px;
    height: 26px;
    text-align: center;
    left: -65px;
  }

  .intro-text {
    width: 260px;
    height: 200px;
    bottom: 40px;
    left: -110px;
  }

  .fs-tt {
    font-size: 18px;
  }

  .tv-py {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .mv-ts {
    font-size: 2rem;
  }

  .tv-container {
    width: 100%;
    height: 100vh;
  }

  .fs\:mi {
    font-size: 2rem;
  }

  .fs\:mi2 {
    font-size: 1rem;
  }
}

@media (min-width: 1440px) {
  .pl {
    padding-left: 6rem;
  }

  .tl\:show {
    display: block;
  }

  .fs\:mi {
    font-size: 2.5rem;
  }

  .fs\:mi2 {
    font-size: 1.2rem;
  }

  .marker {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 9999px;
    background-color: #3a3a3a;
  }

  .tfp {
    top: 0;
  }

  .bfp {
    bottom: 0;
  }

  .tline {
    display: inline-block;
    width: 30%;
    height: 8px;
    background-color: #3a3a3a;
  }

  .lbp {
    bottom: 6px;
  }

  .node-intro {
    top: 30px;
    font-size: 18px;
    width: 200px;
    height: 28px;
    text-align: center;
    left: -70px;
  }

  .intro-text {
    width: 350px;
    height: 200px;
    bottom: 40px;
    left: -150px;
  }

  .fs-tt {
    font-size: 20px;
  }

  .tv-py {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .mv-ts {
    font-size: 3rem;
  }

  .tv-container {
    width: 100%;
    height: 100vh;
  }
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.bg-mask {
  background-image: linear-gradient(180deg,
      rgba(1, 1, 1, 0) 45.08%,
      rgba(1, 1, 1, 0) 49.56%,
      rgba(1, 1, 1, 0.01) 53.84%,
      rgba(1, 1, 1, 0.02) 57.92%,
      rgba(1, 1, 1, 0.04) 61.84%,
      rgba(1, 1, 1, 0.06) 65.62%,
      rgba(1, 1, 1, 0.09) 69.27%,
      rgba(1, 1, 1, 0.12) 72.82%,
      rgba(1, 1, 1, 0.16) 76.29%,
      rgba(1, 1, 1, 0.2) 79.7%,
      rgba(1, 1, 1, 0.24) 83.06%,
      rgba(1, 1, 1, 0.29) 86.41%,
      rgba(1, 1, 1, 0.34) 89.75%,
      rgba(1, 1, 1, 0.39) 93.12%,
      rgba(1, 1, 1, 0.44) 96.53%,
      rgba(1, 1, 1, 0.5) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
}

z-50 {
  z-index: 50;
}

.text-ta {
  color: #3a3a3a;
}

.text-tat {
  color: #c0c0c0;
}

.timeline {
  left: 0;
  right: 0;
  bottom: 6rem;
}

/* @media (max-width: 743px) {
  .sm\:hidden {
    display: none;
  }
} */

/* @media (min-width: 744px) {
  .tlg\:hidden {
    display: none;
  }
} */
</style>
